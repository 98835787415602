<template>
  <div class="contacts_container">
    <MemberTitle :memberTitle="'提现明细'" />
    <div class="contacts_info">
      <div class="withdrawal_header">
        <span>提现明细</span>
      </div>
      <div class="withdrawal_info">
        <div>
          <span>申请单号：</span>
          <span>{{withdrawList.data.withdrawNo}}</span>
        </div>
        <div>
          <span>提现金额：</span>
          <span>{{withdrawList.data.withdrawMoney}}</span>
        </div>
        <!-- <div>
          <span>手续费：</span>
          <span>123432141234</span>
        </div> -->
        <div>
          <span>收款银行：</span>
          <span>{{withdrawList.data.dueBank}}</span>
        </div>
        <div>
          <span>收款账号：</span>
          <span>{{withdrawList.data.dueAccount}}</span>
        </div>
        <div>
          <span>开户人姓名：</span>
          <span>{{withdrawList.data.accountHolder}}</span>
        </div>
        <div>
          <span>创建时间：</span>
          <span>{{withdrawList.data.createTime}}</span>
        </div>
        <div>
          <span>状态：</span>
          <span>{{
            withdrawList.data.state == 1
            ? '未支付'
            : withdrawList.data.state == 2
            ? '已支付'
            : '提现失败'}}</span>
        </div>
        <div v-if="withdrawList.data.state == 2">
          <span>支付时间：</span>
          <span>{{withdrawList.data.payTime}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MemberTitle from '../../../components/MemberTitle.vue'
import { useRoute } from 'vue-router'
import { reactive, onMounted,getCurrentInstance } from 'vue'
export default {
  name: 'contacts',
  components: { MemberTitle },
  setup() {
    const route = useRoute() //实例化路由信息
    const withdrawList = reactive({ data:{} }) //提现明细菜单
    const {proxy} = getCurrentInstance()    //导出vue全局注册的方法

    //获取提现明细信息
    // const gewithDrawList = async() => {
    //   const withdrawId = route.query.id
    //   let ret = await proxy.$get(`/v3/member/front/memberWithdraw/detail?withdrawId=`+withdrawId)
    //   withdrawId.data = ret.data
    // }

    //挂载周期
    onMounted(async() => {
      const withdrawId = route.query.id
      let ret = await proxy.$get(`/v3/member/front/memberWithdraw/detail?withdrawId=`+withdrawId)
      withdrawList.data = ret.data
      console.log(withdrawList.data)
    })
    return{
      withdrawList
    }
  },
}
</script>

<style lang="scss">
.contacts_container {
  padding-top: 20px;
  padding-left: 50px;
  .member_title_breadcrumb {
    width: 1010px;
    float: left;
    border: 1px solid rgba(187, 187, 187, 0.39);
    margin-left: 20px;
    .el-breadcrumb__inner {
      color: #101010 !important;
    }
  }
  .contacts_info {
    width: 1010px;
    height: 800px;
    background-color: #fff;
    float: left;
    border: 1px solid rgba(187, 187, 187, 0.39);
    border-top: 0;
    margin-left: 20px;
    .withdrawal_header {
      width: 100%;
      height: 57px;
      background-color: #fff;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(187, 187, 187, 0.2);
      span {
        color: #101010;
        width: 64px;
        height: 24px;
        font-size: 16px;
        text-align: left;
        font-weight: 550;
        font-family: SourceHanSansSC-bold;
        margin-left: 19px;
      }
    }
    .withdrawal_info {
      display: flex;
      flex-direction: column;
      margin-left: 57px;
      margin-top: 43px;
      div {
        display: flex;
        align-items: center;
        height: 44px;
        color: rgba(16, 16, 16, 100);
        font-size: 14px;
        font-family: SourceHanSansSC-regular;
        span:nth-of-type(1) {
          width: 84px;
          text-align: right;
        }
      }
    }
  }
}
</style>